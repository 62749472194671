<template>

  <v-container id="contactsPartner" class="h-100">

    <div class="back-button text-right">
      <v-btn icon @click="goBack">
        <v-icon color="grey darken-2">mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <div class="pb-3 mb-3">
      <div class="top-text my-3" v-if="account_type == 1">
        <h1 class="title is-1 thin black--text">Кого</h1>
        <h1 class="title is-1 black--text">везём?</h1>
      </div>
      <div class="top-text my-3" v-else>
        <h1 class="title is-1 thin black--text">Кто</h1>
        <h1 class="title is-1 black--text">везёт?</h1>
      </div>
    </div>

    <div class="remaining-space">
      <p class="title is-4 mb-1" v-if="partner.first_name">
        Фамилия {{ partner.first_name }}
      </p>

      <p class="title is-4 mb-1" v-if="partner.name">
        Имя {{ partner.name }}
      </p>

      <p class="title is-4 mb-3" v-if="partner.last_name">
        Отчество {{ partner.last_name }}
      </p>

      <p class="title is-4 mb-0" v-if="partner.number">
        Телефон <a class="yandex-color" :href="`tel:${phoneWrapper(partner.number)}`">{{ phoneWrapper(partner.number) }}</a>
      </p>
    </div>

    <div class="pa-3 pb-0" style="text-align: center;">
      <p class="buttons mb-0 flex justify-center align-center">
        <b-button @click="goBack" class="w-100" type="is-info is-light">
          Назад
        </b-button>
      </p>
    </div>

  </v-container>

</template>

<script>
export default {
  name: 'contactsPartner',
  data() {
    return {
    };
  },
  created() {
    this.$store.dispatch('GET_ORDER_PARTNER', this.orderId).then(() => {
      console.log('Партнёр по заказу: ', this.$store.getters.ORDER_PARTNER);
    }).catch((error) => {
      console.error('Ошибка: ', error);
      this.$buefy.toast.open({
        message: 'Произошла ошибка. Попробуйте ещё раз',
        type: 'is-danger',
      })
    });
  },
  computed: {
    account_type() {
      return this.$store.getters.ACCOUNT_TYPE;
    },
    orderId() {
      return this.$route.params.id;
    },
    partner() {
      return this.$store.getters.ORDER_PARTNER.details;
    },
  },
  methods: {
    phoneWrapper(phone) {
      if (phone && phone !== '') {
        if (phone[0] === '+') {
          return phone
        } else if (phone[0] === '7') {
          return '+' + phone
        }
        return '+7' + phone
      }
      return '';
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.block-content img {
  max-width: 100%;
  height: auto;
}

.title.is-1 {
  font-size: 4.5rem !important;
}

.title.is-1.thin {
  font-weight: 300;
}

#contactsPartner.container {
  display: flex;
  flex-direction: column;
}

.remaining-space {
  flex-grow: 1;
}

.title.is-4 {
  font-size: 1.5rem !important;
  line-height: normal !important;
}

</style>
